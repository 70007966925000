import type { PluginOptions } from 'vue-toastification';
import type { ToastVariant } from '@/shared/components/toast/index.vue';

export function showToast(title: string, subtitle: string, variant: ToastVariant, options?: PluginOptions) {
  if (!process.client) return () => ({});
  const { $toast } = useNuxtApp();
  ($toast as any).showToast(title, subtitle, variant, options);
}

export function clearToasts() {
  if (!process.client) return () => ({});
  const { $toast } = useNuxtApp();
  ($toast as any).clearToasts();
}
