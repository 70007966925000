import { getCookie, setCookie, deleteCookie } from 'h3';

export enum COOKIE {
  VISITED = 'visited',
  AVAILABLE_PAGES = 'availablePages',
  SESSION_ID = 'sessionId',
  EXPIRE_AT = 'expireAt',
  IS_PREVIEW = 'isPreview',
  SUBSCRIBER_ID = 'subscriberId',
  PAYMENT_ERROR = 'paymentError',
  USER_ALREADY_SUBSCRIBED = 'userAlreadySubscribed',
  BYPASS_REGISTER = 'bypassRegister',
  UTM = 'utmInfo',
  LANG = 'language',
  PREVIEW_HASH = 'previewHash',
  SLUG = 'slug',
  LAST_STORE_HASH = 'lastStoreHash',
  LOAD_IMAGES = 'loadImages',
  COOKIE_CONSENT = 'cookieConsent',
  SHOW_COOKIE = 'showCookie',
  FBCLICK_ID = '_fbc',
  FBBROWSER_ID = '_fbp'
}

export function getTimeAsUTC(date?: any) {
  const current = date ? new Date(date) : new Date();
  return Date.UTC(
    current.getUTCFullYear(),
    current.getUTCMonth(),
    current.getUTCDate(),
    current.getUTCHours(),
    current.getUTCMinutes(),
    current.getUTCSeconds(),
    current.getUTCMilliseconds()
  );
}

export function generateExpireTime(date?: Date) {
  const oneMin = 1 / 24 / 60;
  const time = oneMin * 30; // This is session expire time. You can change all expire time just here
  const d = new Date(getTimeAsUTC(date));
  d.setTime(d.getTime() + (time * 24 * 60 * 60 * 1000));

  return {
    num: time,
    date: d,
    iso: d.toISOString(),
    utc: d.toUTCString(),
    client: d.toString()
  }
}

export function setClientCookie(cname: string, cvalue: string, exdays?: Date | number | null, path = '/', domain?: string) {
  if (!globalThis.document) return false;
  let expires = '';
  if (exdays !== undefined) {
    if (exdays instanceof Date) {
      expires = 'expires=' + new Date(getTimeAsUTC(exdays)).toUTCString();
    } else {
      if (exdays === null) exdays = 1;
      const d = new Date(getTimeAsUTC());
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      expires = 'expires=' + d.toUTCString();
    }
  }

  let cookie = `${cname}=${cvalue};${expires};path=${path}`;
  if (domain) cookie += `;domain=${domain};`;

  globalThis.document.cookie = cookie;
}

export function getClientCookie(cname: string) {
  if (!(globalThis.document)) return false
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(globalThis.document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return decodeURI(c.substring(name.length, c.length));
    }
  }
  return false;
}

export function deleteClientCookie(cname: string, path = '/') {
  if (!(globalThis.document)) return false;
  if (path.charAt(0) !== '/') path = `/${path}`;
  globalThis.document.cookie = `${cname}=; expires=${new Date(getTimeAsUTC(1970)).toUTCString()}; path=${path};`;
}

export function splitCookies(cookieString: string) {
  const obj: Record<string, any> = {}
  if (!cookieString) return obj
  for (const item of cookieString.split(';')) {
    const [key, value] = item.split('=');
    if (key) obj[key?.trim()] = value?.trim();
  }

  return obj
}

export function getNamedCookie(cookieName: string, event?: any) {
  if (!event) {
    const app = useNuxtApp()
    event = app.ssrContext?.event;
  }
  if (event) {
    return decodeURI(getCookie(event, cookieName) || '');
  }

  return getClientCookie(cookieName);
}

export function setNamedCookie(payload: {
  cookieName: string;
  value: string;
  exdays?: Date | number | null | undefined;
  path?: string;
  forcePath?: boolean;
}, event?: any) {
  const { cookieName, value, path = '/' } = payload;
  const pathStr =  path.charAt(0) === '/' ? path : `/${path}`;
  let { exdays } = payload;

  if (!event) {
    const app = useNuxtApp()
    event = app.ssrContext?.event;
  }

  if (event) {
    let expires = undefined;
    if (exdays !== undefined) {
      if ((exdays instanceof Date)) {
        expires = exdays;
      } else {
        if (exdays === null) exdays = 1;
        expires = new Date(getTimeAsUTC());
        expires.setTime(expires.getTime() + (exdays * 24 * 60 * 60 * 1000));
      }
    }

    setCookie(event, cookieName, value, {
      expires,
      path: pathStr,
      encode(val) { return val; }
    })
  }

  return setClientCookie(cookieName, value, exdays, pathStr)
}

export function deleteNamedCookie(payload: {
  name: string;
  path?: string;
},event?: any) {
  if (!event) {
    const app = useNuxtApp()
    event = app.ssrContext?.event;
  }

  if (event) return deleteCookie(event, payload.name, { path: payload.path });
  return deleteClientCookie(payload.name, payload.path);
}
