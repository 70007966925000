import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';

export const registerSection1 = createSection({
  type: 'register',
  name: 'Registration',
  uniqName: 'Register1',
  options: {
    visible: true,
    removable: false,
    hideable: false,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 32, bottom: 32, left: 4, right: 4 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col max-w-[430px] w-full my-0 mx-auto' } })
  .element<'text'>({
    type: 'text',
    value: 'Please enter your email address',
    name: 'Title',
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ tablet: 20, mobile: 20 }),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'registration'>({
    type: 'registration',
    name: 'Registration Form',
    style: {
      borderWidth: '1px',
      borderColor: '#E7EAEE',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF'
    },
    options: {
      visible: true,
      hideable: false
    },
    elements: [
      createElement<'button'>({
        type: 'button',
        value: 'Continue',
        tagName: 'button',
        attr: { class: 'w-full', type: 'submit' },
        options: {
          visible: true,
          hideable: false
        },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      }),
      createElement<'text'>({
        type: 'text',
        slotName: 'titleText',
        value: 'Your account will be created with this e-mail address.',
        options: {
          visible: true,
          allowLinks: true,
          ...createResponsiveFontSizeOption({ tablet: 16, mobile: 16 })
        },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          color: '',
          marginTop: '8px',
          marginBottom: '24px',
          fontWeight: 'bold',
          lineHeight: '1.5'
        }
      })
    ]
  })
  .element<'translation'>({
    type: 'translation',
    name: 'Footer Text',
    value: 'generate.legalDocInfoElement.registerSec',
    options: {
      visible: true,
      hideable: false,
      customizeFontColor: true,
      ...createResponsiveFontSizeOption({ tablet: 10, mobile: 10 }),
      margin: {
        desktop: { top: 32, bottom: 0, left: 0, right: 0 },
        tablet: { top: 32, bottom: 0, left: 0, right: 0 },
        mobile: { top: 32, bottom: 0, left: 16, right: 16 }
      }
    },
    style: {
      fontSize: '10px',
      color: '#737380',
      lineHeight: '1.5'
    }
  })
  .done()
  .done();
