import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';

export const questionSection1 = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'SurveyBodySelection1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, left: 20, bottom: 32, right: 20 },
      tablet: { top: 32, left: 20, bottom: 32, right: 20 },
      mobile: { top: 12, left: 20, bottom: 24, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'text'>({
    type: 'text',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, left: 0, bottom: 16, right: 0 },
        tablet: { top: 0, left: 0, bottom: 16, right: 0 },
        mobile: { top: 0, left: 0, bottom: 16, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, left: 0, bottom: 32, right: 0 },
        tablet: { top: 0, left: 0, bottom: 32, right: 0 },
        mobile: { top: 0, left: 0, bottom: 32, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'selection'>({
    type: 'selection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 4, max: 6 },
      buttons: {
        fontFamily: '',
        fontSize: 14,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#B4BFC5', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        },
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        }
      ]
    },
    elements: [
      createElement<'button'>({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
  .done();

export const questionSection2 = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'SurveyBodySelection2',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 0, left: 0, bottom: 0, right: 0 },
      tablet: { top: 32, left: 0, bottom: 0, right: 0 },
      mobile: { top: 0, left: 0, bottom: 0, right: 0 },
    }
  }
})
  .column({
    colspan: 6,
    attr: {
      class: 'px-20 pt-[32px] pb-[24px] md:pt-0 lg:pb-0 md:flex md:flex-col md:justify-center'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col' },
    elements: [
      createElement<'text'>({
        type: 'text',
        value: 'Question Title',
        options: {
          visible: true,
          isTitle: true,
          ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
          margin: {
            desktop: { top: 0, bottom: 16, left: 0, right: 0 },
            tablet: { top: 0, bottom: 16, left: 0, right: 0 },
            mobile: { top: 0, bottom: 16, left: 0, right: 0 },
          }
        },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          lineHeight: '1.3'
        }
      }),
      createElement<'text'>({
        type: 'text',
        value: 'Description',
        options: {
          visible: true,
          isDesc: true,
          ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
          margin: {
            desktop: { top: 0, bottom: 32, left: 0, right: 0 },
            tablet: { top: 0, bottom: 32, left: 0, right: 0 },
            mobile: { top: 0, bottom: 32, left: 0, right: 0 },
          }
        },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          lineHeight: '1.3'
        }
      })
    ]
  })
  .element<'selection'>({
    type: 'selection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 1, max: 4 },
      buttons: {
        fontFamily: '',
        fontSize: 14,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#B4BFC5', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        },
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        }
      ]
    },
    elements: [
      createElement<'button'>({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
  .column({ colspan: 6 })
  .element<'image'>({
    type: 'image',
    name: 'Image',
    value: '/editor/anil-kumar-unsplash.jpg',
    style: { width: '100%' },
    options: { visible: true, fullSize: true }
  })
  .done()
  .done();

export const questionSection3 = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'SurveyBodySelection3',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 0, left: 0, bottom: 0, right: 0 },
      tablet: { top: 0, left: 0, bottom: 0, right: 0 },
      mobile: { top: 0, left: 0, bottom: 0, right: 0 },
    }
  }
})
  .column({
    colspan: 6,
    attr: { class: 'md:flex md:flex-col md:justify-center' }
  })
  .element<'image'>({
    type: 'image',
    name: 'Image',
    value: '/editor/anil-kumar-unsplash.jpg',
    style: { width: '100%' },
    options: { visible: true, fullSize: true }
  })
  .done()
  .column({
    colspan: 6,
    attr: { class: 'px-20 pt-[32px] pb-[24px] lg:pt-0 lg:pb-0 md:flex md:flex-col md:justify-center' }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col' },
    elements: [
      createElement<'text'>({
        type: 'text',
        value: 'Question Title',
        options: {
          visible: true,
          isTitle: true,
          ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
          margin: {
            desktop: { top: 0, bottom: 16, left: 0, right: 0 },
            tablet: { top: 0, bottom: 16, left: 0, right: 0 },
            mobile: { top: 0, bottom: 16, left: 0, right: 0 },
          }
        },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          lineHeight: '1.3'
        }
      }),
      createElement<'text'>({
        type: 'text',
        value: 'Description',
        options: {
          visible: true,
          isDesc: true,
          ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
          margin: {
            desktop: { top: 0, bottom: 32, left: 0, right: 0 },
            tablet: { top: 0, bottom: 32, left: 0, right: 0 },
            mobile: { top: 0, bottom: 32, left: 0, right: 0 },
          }
        },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          lineHeight: '1.3'
        }
      })
    ]
  })
  .element<'selection'>({
    type: 'selection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 1, max: 4 },
      buttons: {
        fontFamily: '',
        fontSize: 14,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#B4BFC5', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        },
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        }
      ]
    },
    elements: [
      createElement<'button'>({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
  .done();

export const questionSection4 = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'SurveyBodyImageSelection1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, left: 20, bottom: 32, right: 20 },
      tablet: { top: 32, left: 20, bottom: 32, right: 20 },
      mobile: { top: 24, left: 20, bottom: 24, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, left: 0, bottom: 16, right: 0 },
        tablet: { top: 0, left: 0, bottom: 16, right: 0 },
        mobile: { top: 0, left: 0, bottom: 16, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, left: 0, bottom: 32, right: 0 },
        tablet: { top: 0, left: 0, bottom: 32, right: 0 },
        mobile: { top: 0, left: 0, bottom: 32, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'imageselection'>({
    type: 'imageselection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 4, max: 6 },
      buttons: {
        fontFamily: '',
        fontSize: 12,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#E5E5EA', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        },
        {
          name: 'Option',
          type: 1,
          image: '',
          optionId: ''
        }
      ]
    },
    elements: [
      createElement<'button'>({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
.done();

export const questionSection5 = createSection({
  type: 'question',
  name: 'Info Page',
  uniqName: 'InfoPage1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 24, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12 })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: {
          width: '280px',
          height: '300px',
          objectFit: 'cover'
        },
        options: {
          visible: true,
          margin: {
            desktop: { top: 0, bottom: 32, left: 0, right: 0 },
            tablet: { top: 0, bottom: 32, left: 0, right: 0 },
            mobile: { top: 0, bottom: 24, left: 0, right: 0 },
          }
        }
      })
    ]
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col' },
    elements: [
      createElement<'text'>({
        type: 'text',
        name: 'Text 1',
        value: 'Info Title',
        options: {
          visible: true,
          isTitle: true,
          ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
          margin: {
            desktop: { top: 0, bottom: 8, left: 0, right: 0 },
            tablet: { top: 0, bottom: 8, left: 0, right: 0 },
            mobile: { top: 0, bottom: 8, left: 0, right: 0 }
          }
        },
        style: {
          fontWeight: 'bold',
          fontSize: '16px',
          textAlign: 'center',
          lineHeight: '1.3'
        }
      }),
      createElement<'text'>({
        type: 'text',
        name: 'Text 2',
        value: 'Description',
        options: {
          visible: true,
          isDesc: true,
          ...createResponsiveFontSizeOption({tablet: 14, mobile: 14}),
          margin: {
            desktop: { top: 0, bottom: 32, left: 0, right: 0 },
            tablet: { top: 0, bottom: 32, left: 0, right: 0 },
            mobile: { top: 0, bottom: 24, left: 0, right: 0 },
          }
        },
        style: {
          fontSize: '14px',
          textAlign: 'center',
          lineHeight: '1.3'
        }
      })
    ]
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center' },
    style: {
      position: 'sticky',
      bottom: '0'
    },
    elements: [
      createElement<'button'>({
        type: 'button',
        tagName: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        options: { visible: true, hideable: false, allowLinks: false },
        on: { click: 'nextQuestion' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
.done();

export const timeEntrySection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'TimeEntry1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'timeentry'>({
        type: 'timeentry',
        style: { hover: {} },
        options: {
          visible: true
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const locationPickerSection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'LocationPicker1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'locationpicker'>({
        type: 'locationpicker',
        style: { hover: {} },
        options: {
          visible: true
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            tagName: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const dateEntrySection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'DateEntry1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'dateentry'>({
        type: 'dateentry',
        style: { hover: {} },
        options: {
          visible: true
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const singleRowTextSection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'SingleRowText1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'singlerowtext'>({
        type: 'singlerowtext',
        style: { hover: {} },
        options: {
          visible: true,
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const numericInputSection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'NumericInput1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'numericinput'>({
        type: 'numericinput',
        style: { hover: {} },
        options: {
          visible: true,
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const fileSection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'File1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'file'>({
        type: 'file',
        options: {
          visible: true,
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const heightInputSection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'HeightInput1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'heightinput'>({
        type: 'heightinput',
        style: { hover: {} },
        options: {
          visible: true,
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const weightInputSection = createSection({
  type: 'question',
  name: 'Quiz Body',
  uniqName: 'WeightInput1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 12, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: { width: '280px', height: '300px', objectFit: 'cover' },
        options: {
          visible: false,
          fullSize: false,
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 40, left: 0, right: 0 }
          }
        }
      })
    ]
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: {
      visible: true,
      isTitle: true,
      ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}),
      margin: {
        desktop: { top: 0, bottom: 16, left: 0, right: 0 },
        tablet: { top: 0, bottom: 16, left: 0, right: 0 },
        mobile: { top: 0, bottom: 16, left: 0, right: 0 }
      }
    },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: {
      visible: true,
      isDesc: true,
      ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}),
      margin: {
        desktop: { top: 0, bottom: 32, left: 0, right: 0 },
        tablet: { top: 0, bottom: 32, left: 0, right: 0 },
        mobile: { top: 0, bottom: 32, left: 0, right: 0 }
      }
    },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '1.3'
    }
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement<'weightinput'>({
        type: 'weightinput',
        style: { hover: {} },
        options: {
          visible: true,
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();
