import { createSection, createResponsiveFontSizeOption } from '@shared/utils/section';

export const surveyEndingSection = createSection({
  type: 'surveyending',
  name: 'Quiz Ending',
  uniqName: 'SurveyEnding1',
  options: {
    visible: true,
    removable: false,
    hideable: false,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 24, bottom: 24, left: 20, right: 20 }
    }
  }
})
  .column({ colspan: 12, attr: { class: 'flex flex-col' } })
  .element<'text'>({
    type: 'text',
    value: 'Thank you for the information you have provided',
    name: 'Title',
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ tablet: 20, mobile: 16 }),
      margin: {
        desktop: { top: 0, bottom: 24, left: 0, right: 0 },
        tablet: { top: 0, bottom: 24, left: 0, right: 0 },
        mobile: { top: 0, bottom: 24, left: 0, right: 0 }
      }
    },
    attr: { class: '!text-16 md:!text-20' },
    style: {
      fontWeight: '600',
      textAlign: 'center',
      lineHeight: '1.5'
    }
  })
  .element<'text'>({
    type: 'text',
    value: 'Your personalized plan is being prepared',
    name: 'Description',
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ tablet: 16, mobile: 14 })
    },
    attr: { class: '!text-14 md:!text-16' },
    style: {
      fontWeight: '600',
      textAlign: 'center',
      lineHeight: '1.5'
    }
  })
  .element<'endingprogress'>({
    type: 'endingprogress',
    options: {
      visible: true,
      hideable: false,
      bar: {
        customize: false,
        bgColor: '#E5E5EA',
        trackColor: ''
      },
      margin: {
        desktop: { top: 64, bottom: 0, left: 0, right: 0 },
        tablet: { top: 64, bottom: 0, left: 0, right: 0 },
        mobile: { top: 64, bottom: 0, left: 0, right: 0 },
      }
    }
  })
  .element<'text'>({
    type: 'text',
    name: 'progressText',
    value: 'Please wait...',
    options: {
      visible: true,
      customizeFontColor: true,
      ...createResponsiveFontSizeOption({ tablet: 14, mobile: 14 }),
      margin: {
        desktop: { top: 8, bottom: 0, left: 0, right: 0 },
        tablet: { top: 8, bottom: 0, left: 0, right: 0 },
        mobile: { top: 8, bottom: 0, left: 0, right: 0 },
      }
    },
    style: {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '400',
      color: '',
      lineHeight: '1.5'
    }
  })
  .done()
  .done();
