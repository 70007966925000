import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';

export const paywallSection1 = createSection({
  type: 'paywall',
  name: 'Paywall',
  uniqName: 'Paywall1',
  options: {
    visible: true,
    removable: false,
    hideable: false,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 32, bottom: 32, left: 20, right: 20 }
    }
  },
})
  .column({ colspan: 12, attr: { class: 'max-w-[560px] w-full my-0 mx-auto' } })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col gap-24 sm:gap-32'},
    elements: [
      createElement<'text'>({
        type: 'text',
        value: 'Grab your offer now!',
        name: 'Title',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ tablet: 20, mobile: 16 }, { tablet: false, mobile: true }),
          margin: {
            desktop: { top: 0, bottom: 0, left: 0, right: 0 },
            tablet: { top: 0, bottom: 0, left: 0, right: 0 },
            mobile: { top: 0, bottom: 0, left: 10, right: 10 }
          }
        },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          lineHeight: '1.3'
        }
      }),
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/theme-defaults/paywall_image_1.jpg',
        attr: { class: '!mx-auto' },
        style: { width: '560px', height: '240px', borderRadius: '6px' },
        options: { visible: true, fullSize: false }
      }),
      createElement<'text'>({
        type: 'text',
        value: 'Choose Your Plan',
        name: 'Text',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ tablet: 16, mobile: 16 })
        },
        attr: { class: '!mx-[10px] sm:!mx-0' },
        style: {
          fontWeight: 'bold',
          fontSize: '16px',
          textAlign: 'center',
          lineHeight: '1.2'
        }
      })
    ]
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'mt-8 md:mt-16 !mx-[10px] sm:!mx-0'},
    elements: [
      createElement<'plan-selection'>({
        type: 'plan-selection',
        style: {},
        options: { visible: true, hideable: false },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col px-[10px] sm:max-w-[300px] w-full mx-auto'},
    elements: [
      createElement<'translation'>({
        type: 'translation',
        value: 'generate.legalDocInfoElement.paywallSec',
        name: 'Footer Text',
        options: {
          visible: true,
          hideable: true,
          ...createResponsiveFontSizeOption({ tablet: 10, mobile: 10 }),
          margin: {
            desktop: { top: 32, bottom: 0, left: 0, right: 0 },
            tablet: { top: 32, bottom: 0, left: 0, right: 0 },
            mobile: { top: 32, bottom: 0, left: 0, right: 0 },
          }
        },
        style: {
          fontSize: '10px',
          textAlign: 'center',
          color: '#737380',
          lineHeight: '1.5'
        }
      })
    ]
  })
  .done()
  .done();
