import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';

export const paymentSection1 = createSection({
  type: 'payment',
  name: 'Payment Body',
  uniqName: 'PaymenyBody1',
  options: {
    visible: true,
    removable: false,
    hideable: false,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 32, bottom: 32, left: 8, right: 8 }
    }
  }
})
  .column({ colspan: 12 })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col px-8 md:px-0' },
    elements: [
      createElement<'text'>({
        type: 'text',
        name: 'Text 1',
        value: 'Grab your offer now',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 20, tablet: 20 }),
          margin: {
            desktop: { top: 0, bottom: 8, left: 0, right: 0 },
            tablet: { top: 0, bottom: 8, left: 0, right: 0 },
            mobile: { top: 0, bottom: 8, left: 0, right: 0 },
          }
        },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          lineHeight: '1.5'
        }
      }),
      createElement<'conditional-text'>({
        type: 'conditional-text',
        name: 'Text 2',
        value: 'Please provide payment details to continue',
        conditionalValue: JSON.stringify({
          package_with_trial: {
            label: 'pages.editor.elements.contextMenu.package_with_trial',
            value: 'Try {TRIAL_PERIOD} for {TRIAL_PRICE}'
          },
          plan_with_no_trial: {
            label: 'pages.editor.elements.contextMenu.plan_with_no_trial',
            value: 'Please provide payment details to continue'
          },
          package_with_trial_used: {
            label: 'pages.editor.elements.contextMenu.package_with_trial_used',
            value: 'Please provide payment details to continue'
          },
        }),
        options: {
          visible: true,
          conditionalOptions: {
            package_with_trial: {
              customizeFontType: false,
              customizeFontColor: false,
              ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
            },
            plan_with_no_trial: {
              customizeFontType: false,
              customizeFontColor: false,
              ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
            },
            package_with_trial_used: {
              customizeFontType: false,
              customizeFontColor: false,
              ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
            }
          },
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 10, left: 0, right: 0 }
          }
        },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          lineHeight: '1.5'
        }
      })
    ]
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col sm:flex-row justify-center gap-[24px] mx-auto' },
    style: { maxWidth: '944px' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!hidden sm:!flex sm:!flex-[0_1_460px] !h-full !w-full has-fixed-height-image' },
        wrapperClass: '!hidden sm:!flex sm:!flex-[0_1_460px]',
        style: { borderRadius: '6px', height: '100%', objectFit: 'cover' },
        options: { visible: true, fullSize: true }
      }),
      createElement<'payment'>({
        type: 'payment',
        name: 'Payment Form',
        attr: { class: 'sm:!flex-[0_1_460px]' },
        wrapperClass: 'sm:!flex-[0_1_460px]',
        style: {
          borderWidth: '1px',
          borderColor: '#E7EAEE',
          borderRadius: '6px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'center'
        },
        options: {
          visible: true,
          hideable: false
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'Start Now',
            conditionalValue: JSON.stringify({
              package_with_trial: {
                label: 'pages.editor.elements.contextMenu.package_with_trial',
                value: 'Start {TRIAL_PERIOD} Trial'
              },
              plan_with_no_trial: {
                label: 'pages.editor.elements.contextMenu.plan_with_no_trial',
                value: 'Start Now'
              },
              package_with_trial_used: {
                label: 'pages.editor.elements.contextMenu.package_with_trial_used',
                value: 'Start Now'
              },
            }),
            tagName: 'button',
            attr: { class: 'w-full', type: 'submit' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          }),
          createElement<'conditional-text'>({
            type: 'conditional-text',
            slotName: 'infoText',
            value: 'You will be charged <b style="color:#0D0626;">{TRIAL_PRICE} for {TRIAL_PERIOD}</b>, then {PRICE} per {PERIOD}.',
            conditionalValue: JSON.stringify({
              package_with_trial: {
                label: 'pages.editor.elements.contextMenu.package_with_trial',
                value: 'You will be charged <b style="color:#0D0626;">{TRIAL_PRICE} for {TRIAL_PERIOD}</b>, then {PRICE} per {PERIOD}.'
              },
              plan_with_no_trial: {
                label: 'pages.editor.elements.contextMenu.plan_with_no_trial',
                value: 'You will be charged {PRICE} per {PERIOD}.'
              },
              package_with_trial_used: {
                label: 'pages.editor.elements.contextMenu.package_with_trial_used',
                value: 'You can use a trial only once. Since you have used it before, you will be charged {PRICE} per {PERIOD}.'
              },
            }),
            options: {
              visible: true,
              allowLinks: true,
              conditionalOptions: {
                package_with_trial: {
                  customizeFontType: false,
                  customizeFontColor: true,
                  ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
                },
                plan_with_no_trial: {
                  customizeFontType: false,
                  customizeFontColor: false,
                  ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
                },
                package_with_trial_used: {
                  customizeFontType: false,
                  customizeFontColor: false,
                  ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
                }
              }
            },
            style: {
              fontSize: '10px',
              color: '#737380',
              lineHeight : '1.5'
            }
          }),
          createElement<'translation'>({
            type: 'translation',
            name: 'Text',
            slotName: 'infoText2',
            value: 'generate.legalDocInfoElement.paymentEl',
            options: {
              visible: true,
              hideable: false,
              customizeFontColor: true,
              ...createResponsiveFontSizeOption({ mobile: 10, tablet: 10 })
            },
            style: {
              fontSize: '10px',
              color: '#737380',
              lineHeight : '1.5'
            }
          })
        ]
      })
    ]
  })
  .done()
  .done();

export const paymentWithTabsSection = createSection({
  type: 'payment',
  name: 'Payment Body',
  uniqName: 'PaymenyBody2',
  options: {
    visible: true,
    removable: false,
    hideable: false,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 32, bottom: 32, left: 8, right: 8 }
    }
  },
  style: {
    backgroundColor: '#FFFFFF'
  }
})
  .column({ colspan: 12 })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col px-8 md:px-0' },
    elements: [
      createElement<'text'>({
        type: 'text',
        name: 'Text 1',
        value: 'Grab your offer now',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 20, tablet: 20 }),
          margin: {
            desktop: { top: 0, bottom: 8, left: 0, right: 0 },
            tablet: { top: 0, bottom: 8, left: 0, right: 0 },
            mobile: { top: 0, bottom: 8, left: 0, right: 0 }
          }
        },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          lineHeight: '1.5'
        }
      }),
      createElement<'conditional-text'>({
        type: 'conditional-text',
        name: 'Text 2',
        value: 'Please provide payment details to continue',
        conditionalValue: JSON.stringify({
          package_with_trial: {
            label: 'pages.editor.elements.contextMenu.package_with_trial',
            value: 'Try {TRIAL_PERIOD} for {TRIAL_PRICE}'
          },
          plan_with_no_trial: {
            label: 'pages.editor.elements.contextMenu.plan_with_no_trial',
            value: 'Please provide payment details to continue'
          },
          package_with_trial_used: {
            label: 'pages.editor.elements.contextMenu.package_with_trial_used',
            value: 'Please provide payment details to continue'
          },
        }),
        options: {
          visible: true,
          conditionalOptions: {
            package_with_trial: {
              customizeFontType: false,
              customizeFontColor: false,
              ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
            },
            plan_with_no_trial: {
              customizeFontType: false,
              customizeFontColor: false,
              ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
            },
            package_with_trial_used: {
              customizeFontType: false,
              customizeFontColor: false,
              ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
            }
          },
          margin: {
            desktop: { top: 0, bottom: 40, left: 0, right: 0 },
            tablet: { top: 0, bottom: 40, left: 0, right: 0 },
            mobile: { top: 0, bottom: 10, left: 0, right: 0 }
          }
        },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          lineHeight: '1.5'
        }
      })
    ]
  })
  .element<'plain'>({
    type: 'plain',
    attr: { class: 'flex flex-col sm:flex-row justify-center gap-[24px] mx-auto' },
    style: { maxWidth: '944px' },
    elements: [
      createElement<'image'>({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!hidden sm:!flex sm:!flex-[0_1_460px] !h-full !w-full min-h-[600px] has-fixed-height-image' },
        wrapperClass: '!hidden sm:!flex sm:!flex-[0_1_460px]',
        style: { borderRadius: '6px', height: '100%', objectFit: 'cover' },
        options: { visible: true, fullSize: true }
      }),
      createElement<'payment-with-tabs'>({
        type: 'payment-with-tabs',
        name: 'Payment Form',
        attr: { class: 'sm:!flex-[0_1_460px]' },
        wrapperClass: 'sm:!flex-[0_1_460px]',
        style: {
          borderWidth: '1px',
          borderColor: '#E7EAEE',
          borderRadius: '6px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'center'
        },
        options: {
          visible: true,
          hideable: false
        },
        elements: [
          createElement<'button'>({
            type: 'button',
            value: 'PAY NOW',
            conditionalValue: JSON.stringify({
              package_with_trial: {
                label: 'pages.editor.elements.contextMenu.package_with_trial',
                value: 'Start {TRIAL_PERIOD} Trial'
              },
              plan_with_no_trial: {
                label: 'pages.editor.elements.contextMenu.plan_with_no_trial',
                value: 'Start Now'
              },
              package_with_trial_used: {
                label: 'pages.editor.elements.contextMenu.package_with_trial_used',
                value: 'Start Now'
              },
            }),
            tagName: 'button',
            attr: { class: 'w-full', type: 'submit' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          }),
          createElement<'conditional-text'>({
            type: 'conditional-text',
            slotName: 'infoText',
            value: 'You will be charged <b style="color:#0D0626;">{TRIAL_PRICE} for {TRIAL_PERIOD}</b>, then {PRICE} per {PERIOD}.',
            conditionalValue: JSON.stringify({
              package_with_trial: {
                label: 'pages.editor.elements.contextMenu.package_with_trial',
                value: 'You will be charged <b style="color:#0D0626;">{TRIAL_PRICE} for {TRIAL_PERIOD}</b>, then {PRICE} per {PERIOD}.'
              },
              plan_with_no_trial: {
                label: 'pages.editor.elements.contextMenu.plan_with_no_trial',
                value: 'You will be charged {PRICE} per {PERIOD}.'
              },
              package_with_trial_used: {
                label: 'pages.editor.elements.contextMenu.package_with_trial_used',
                value: 'You can use a trial only once. Since you have used it before, you will be charged {PRICE} per {PERIOD}.'
              },
            }),
            options: {
              visible: true,
              allowLinks: true,
              conditionalOptions: {
                package_with_trial: {
                  customizeFontType: false,
                  customizeFontColor: true,
                  ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
                },
                plan_with_no_trial: {
                  customizeFontType: false,
                  customizeFontColor: false,
                  ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
                },
                package_with_trial_used: {
                  customizeFontType: false,
                  customizeFontColor: false,
                  ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
                },
              }
            },
            style: {
              fontSize: '11px',
              textAlign: 'left',
              color: '#737380',
              lineHeight: '1.5'
            }
          }),
          createElement<'translation'>({
            type: 'translation',
            name: 'Text',
            slotName: 'infoText2',
            value: 'generate.legalDocInfoElement.paymentEl',
            options: {
              visible: true,
              hideable: false,
              customizeFontColor: true,
              ...createResponsiveFontSizeOption({ mobile: 11, tablet: 11 })
            },
            style: {
              fontSize: '11px',
              textAlign: 'left',
              color: '#737380',
              lineHeight: '1.5'
            }
          })
        ]
      })
    ]
  })
  .done()
  .done();
