import { createSection } from '@shared/utils/section';

export const surveyProgressHeader = createSection({
  name: 'Quiz Header',
  type: 'surveyheader',
  uniqName: 'SurveyHeader1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      desktop: { top: 10, left: 0, bottom: 10, right: 0 },
      tablet: { top: 10, left: 0, bottom: 10, right: 0 },
      mobile: { top: 10, left: 0, bottom: 10, right: 0 }
    }
  }
})
  .column({
    colspan: 6,
    attr: { class: 'md:!col-start-4 max-w-[428px] w-full !mx-auto' }
  })
  .element<'surveyprogress'>({
    type: 'surveyprogress',
    options: {
      visible: true,
      activeColor: '',
      bar: {
        customize: false,
        bgColor: '#E5E5EA',
        trackColor: ''
      }
    }
  })
  .done()
  .done();
