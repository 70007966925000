<template>
  <NuxtPage />
  <CookiePopUp />
</template>

<script lang="ts" setup>
import { useSiteStore } from '@/stores/site';
import type { PageOptions } from './shared/types/model';
import { PageType } from './types';
import CookiePopUp from './components/cookie/index.vue';

const route = useRoute();
const { pageType: slug } = getBaseUrl(route?.fullPath);
const { siteData } = useSiteStore()

if (process.client) {
  registerCacheStorage(siteData?.flowImages || [])
}

const pageType = slug as PageType || getFirstPage();
const pageOptions = JSON.parse(siteData?.pageOptions || '{}') as PageOptions;
const pageSettings = pageOptions?.pages?.[pageType] || {};
const forceDesktopBG = !!pageSettings.forceDesktopBG;
const { desktop, tablet, mobile } = pageSettings.backgroundImage || {};
const bg = {
  color: pageSettings.backgroundColor,
  image: {
    desktop: desktop || '',
    tablet: (forceDesktopBG ? desktop : tablet) || '',
    mobile: (forceDesktopBG ? desktop : mobile) || '',
  }
};

useHead({
  style: [
    `:root {
      --app-backgroundColor: ${bg.color};
      --app-backgroundImage-desktop: ${bg.image.desktop};
      --app-backgroundImage-tablet: ${bg.image.tablet};
      --app-backgroundImage-mobile: ${bg.image.mobile};
    }`
  ]
});
</script>

<style lang="postcss">
body {
  background-color: var(--app-backgroundColor, '#FFFFFF');
  background-image: var(--app-backgroundImage-mobile);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-color 0.15s linear;

  @media (min-width: 768px) {
    background-image: var(--app-backgroundImage-tablet);
  }

  @media (min-width: 1024px) {
    background-image: var(--app-backgroundImage-desktop);
  }
}

#__nuxt {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @supports (min-height: 100svh) {
    min-height: 100svh;
  }

  .tw-section.tw-section-footer,
  .tw-section.tw-zotlofooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
