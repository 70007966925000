import { COOKIE } from "../cookie";

const logSyle =
  "background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";

export const GA4 = {
  options: {
    gaCode: null as null | string,
    debug: false,
  },
  hasConsent: () => {
    const consent = getNamedCookie(COOKIE.COOKIE_CONSENT);
    return consent === 'true'
  },

  log(...args: any[]) {
    if (this.options.debug && !process.server) {
      console.log(`%c${(this as any).name || 'GA4'}`, logSyle, ...args);
    }
  },

  gtag(...args: any[]) {
    if (!this.options.gaCode) return;
    if ((globalThis as any).gtag) {
      (globalThis as any).gtag(...args);
    }
    this.log.bind({ ...this, name: 'GA4' })('gtag', args)
  },

  consent(granted: boolean) {
    const value = granted ? 'granted': 'denied';
    this.gtag('consent', 'update', {
      ad_storage: value,
      ad_user_data: value,
      ad_personalization: value,
      analytics_storage: value,
    });
  },

  init(payload: { gaCode: string, debug?: boolean }) {
    if (process.server) return false;
    if (this.options.gaCode) return false
    if (!payload.gaCode) {
      console.warn('GA4 cannot be installed because there is no GA4 Code!');
      return
    }

    this.options.gaCode = payload.gaCode;
    this.options.debug = !!payload.debug;

    if (this.options.debug) {
      this.log.bind({ ...this, name: 'GA4' })('init', this.options.gaCode);
      return;
    }

    if (globalThis.document) {
      (globalThis as any).dataLayer = (globalThis as any).dataLayer || [];

      (function (w: any, d, s, l, i) {
        w[l] = w[l] || [];
        w.gtag = function gtag(...args: any[]){ w[l].push(args); }
        w.gtag('js', new Date());
        w.gtag('config', i);

        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s) as HTMLScriptElement;

        j.async = true;
        j.src = `https://www.googletagmanager.com/gtag/js?id=${i}`;
        f.parentNode?.insertBefore(j, f);
      })(globalThis, globalThis.document, "script", "dataLayer", this.options.gaCode);
    }
  },

  initOnSSR(payload: { gaCode: string, granted?: boolean, debug?: boolean }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (!payload.gaCode) {
      console.warn('GA4 cannot be installed because there is no GA4 Code!');
      return headScripts;
    }

    this.options.gaCode = payload.gaCode;
    this.options.debug = !!payload.debug;

    if (this.options.debug) {
      this.log.bind({ ...this, name: 'GA4' })('init', this.options.gaCode);
      return headScripts;
    }

    let isDefatulGranted = 'denied';
    if (payload.granted || this.hasConsent()) isDefatulGranted = 'granted';

    headScripts.script = [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${this.options.gaCode}`,
        async: true,
      },
      {
        innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('consent', 'default', {
          'ad_storage': '${isDefatulGranted}',
          'ad_user_data': '${isDefatulGranted}',
          'ad_personalization': '${isDefatulGranted}',
          'analytics_storage': '${isDefatulGranted}'
        });
        gtag('js', new Date());
        gtag('config', '${this.options.gaCode}');`
      }
    ];

    return headScripts;
  }
};
