import { default as _91_46_46_46slug_93Sb3zmP9EPhMeta } from "/opt/atlassian/pipelines/agent/build/pages/[...slug].vue?macro=true";
import { default as _404VI8KNBPx5SMeta } from "/opt/atlassian/pipelines/agent/build/pages/404.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Sb3zmP9EPhMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Sb3zmP9EPhMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Sb3zmP9EPhMeta || {},
    alias: _91_46_46_46slug_93Sb3zmP9EPhMeta?.alias || [],
    redirect: _91_46_46_46slug_93Sb3zmP9EPhMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _404VI8KNBPx5SMeta?.name ?? "404",
    path: _404VI8KNBPx5SMeta?.path ?? "/404",
    meta: _404VI8KNBPx5SMeta || {},
    alias: _404VI8KNBPx5SMeta?.alias || [],
    redirect: _404VI8KNBPx5SMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/404.vue").then(m => m.default || m)
  }
]