import {
  createSection,
  createElement,
  createResponsiveFontSizeOption,
  createDefaultTextStyleOption
} from '@shared/utils/section';

/**
 * This comment must be here for old payment success pages,
 * tailwind will scan this classes from here:

    bg-[rgba(195,201,213,0.16)]
    mx-auto my-0 flex h-full flex-col lg:grid lg:grid-cols-12
    !max-w-[47px] md:!max-w-[60px] !mx-auto !mb-[6px] md:!mb-8
    gap-24 mb-24 md:gap-32 !text-14 md:!text-16
    h-48 md:h-[62.5px] w-[1px] bg-neutral-200 relative
    h-[10px] w-[10px] !min-h-[auto] !min-w-[auto] rounded-full !bg-neutral-300
    !absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2

 * NOTE: If page uses new payment-result element this class is not necessary.
 */

export const paymentSuccessSection = createSection({
  type: 'paymentSuccess',
  name: 'Payment Success Body',
  uniqName: 'PaymenySuccessBody1',
  options: {
    visible: true,
    removable: false,
    hideable: false,
    spacing: {
      desktop: { top: 32, bottom: 32, left: 20, right: 20 },
      tablet: { top: 32, bottom: 32, left: 20, right: 20 },
      mobile: { top: 32, bottom: 32, left: 16, right: 16 }
    }
  }
})
  .column({ colspan: 12 })
  .element<'plain'>({
    type: 'plain',
    elements: [
      createElement<'payment-result'>({
        type: 'payment-result',
        name: 'Payment Result',
        options: { visible: true },
        elements: [
          // Title Texts for success and warning
          createElement<'translation'>({
            type: 'translation',
            name: 'Text 1',
            conditionalRef: 'successElement',
            slotName: 'titleText',
            value: 'generate.templates.paymentSuccess.titleText',
            options: {
              visible: true,
              textStyle: createDefaultTextStyleOption({ bold: true }),
              ...createResponsiveFontSizeOption({ mobile: 18, tablet: 24 }, { mobile: true, tablet: true })
            },
            style: { textAlign: 'center', fontSize: '24px', color: '', lineHeight: '1' }
          }),
          createElement<'translation'>({
            type: 'translation',
            name: 'Text 1',
            conditionalRef: 'errorElement',
            slotName: 'titleText',
            value: 'generate.templates.paymentSuccess.userAlreadySubscribedWarning',
            options: {
              visible: true,
              hideable: false,
              textStyle: createDefaultTextStyleOption({ bold: true }),
              ...createResponsiveFontSizeOption({ mobile: 18, tablet: 24 }, { mobile: true, tablet: true })
            },
            style: { textAlign: 'center', fontSize: '24px', color: '', lineHeight: '1' }
          }),

          // Title Description
          createElement<'text'>({
            type: 'text',
            name: 'Text 2',
            value: 'Follow the steps below to start using your app',
            slotName: 'titleDesc',
            options: {
              visible: true,
              hideable: false,
              ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 })
            },
            style: { textAlign: 'center', color: '', lineHeight: '1' },
            attr: { class: '!text-14 md:!text-16' }
          }),

          // Access links info text
          createElement<'text'>({
            type: 'text',
            name: 'Text 3',
            slotName: 'accessLinkInfoText',
            value: "Download the app if you haven't already",
            attr: { class: '!text-14 md:!text-16' },
            style: { textAlign: 'center', fontWeight: '600', lineHeight: '1' },
            options: {
              visible: true,
              hideable: false,
              ...createResponsiveFontSizeOption({ tablet: 16, mobile: 14 }),
              margin: {
                desktop: { top: 0, bottom: 16, left: 0, right: 0 },
                tablet: { top: 0, bottom: 16, left: 0, right: 0 },
                mobile: { top: 0, bottom: 16, left: 0, right: 0 },
              }
            }
          }),
          createElement<'button'>({
            type: 'button',
            tagName: 'a',
            name: 'Button 1',
            slotName: 'default',
            value: 'Go to App',
            wrapperClass: 'mt-8',
            options: { visible: true, customizeButtonColor: true, hideable: false, allowLinks: false },
            attr: {
              class: 'w-full !min-h-[40px] md:w-auto md:min-w-[194px] min-h-[48px] px-14 py-10 mt-8',
              target: '_blank',
              'data-generic-app-link': true
            },
            style: {
              color: '',
              fontWeight: '600',
              fontSize: '14px',
              backgroundColor: '#0D0626',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: '#0D0626'
              }
            }
          }),

          // Sign in info for phoneSpecificElement
          createElement<'translation'>({
            type: 'translation',
            conditionalRef: 'phoneSpecificElement',
            slotName: 'signInInfo',
            name: 'Text 4',
            value: 'generate.templates.paymentSuccess.phoneInfo',
            style: { textAlign: 'center', fontSize: '16px', color: '', lineHeight: '1.3' },
            options: {
              visible: true,
              ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 }, { mobile: true, tablet: true }),
              margin: {
                desktop: { top: 0, bottom: 24, left: 0, right: 0 },
                tablet: { top: 0, bottom: 24, left: 0, right: 0 },
                mobile: { top: 0, bottom: 24, left: 0, right: 0 }
              }
            }
          }),
          createElement<'translation'>({
            type: 'translation',
            conditionalRef: 'phoneSpecificElement',
            slotName: 'subscriberId',
            name: 'Text 5',
            value: '{PHONE_NUMBER}',
            options: {
              visible: true,
              textStyle: createDefaultTextStyleOption({ bold: true }),
              ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 }, { mobile: true, tablet: true })
            },
            style: { fontSize: '16px', color: '', textAlign: 'center', lineHeight: '1' }
          }),

          // Sign in info for mailSpecificElement
          createElement<'translation'>({
            type: 'translation',
            conditionalRef: 'mailSpecificElement',
            slotName: 'signInInfo',
            name: 'Text 4',
            value: 'generate.templates.paymentSuccess.mailInfo',
            style: { textAlign: 'center', fontSize: '16px', color: '', lineHeight: '1.3' },
            options: {
              visible: true,
              ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 }, { mobile: true, tablet: true }),
              margin: {
                desktop: { top: 0, bottom: 24, left: 0, right: 0 },
                tablet: { top: 0, bottom: 24, left: 0, right: 0 },
                mobile: { top: 0, bottom: 24, left: 0, right: 0 }
              }
            }
          }),
          createElement<'translation'>({
            type: 'translation',
            conditionalRef: 'mailSpecificElement',
            slotName: 'subscriberId',
            name: 'Text 5',
            value: '{USER_MAIL}',
            options: {
              visible: true,
              textStyle: createDefaultTextStyleOption({ bold: true }),
              ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 }, { mobile: true, tablet: true })
            },
            style: { fontSize: '16px', color: '', textAlign: 'center', lineHeight: '1' }
          }),

          // Extra Info
          createElement<'text'>({
            type: 'text',
            name: 'Text 6',
            slotName: 'extraInfo',
            value: 'That’s all! You can now start using the app.',
            style: { textAlign: 'center', fontWeight: '600', lineHeight: '1' },
            attr: { class: '!text-[15px] md:!text-16' },
            options: {
              visible: true,
              hideable: false,
              ...createResponsiveFontSizeOption({ tablet: 16, mobile: 15 })
            }
          })
        ]
      })
    ]
  })
  .element<'plain'>({
    type: 'plain',
    style: { maxWidth: '587px' },
    attr: { class: 'mx-auto flex flex-col' },
    elements: [
      createElement<'translation'>({
        type: 'translation',
        name: 'Text 7',
        value: 'generate.templates.paymentSuccess.footerInfoTop',
        style: { textAlign: 'center', fontSize: '10px', color: '#737380', lineHeight: '1.5' },
        options: {
          customizeFontColor: true,
          visible: true,
          params: { style: 'color: #007AFF;', target: '_blank' },
          ...createResponsiveFontSizeOption({ mobile: 10, tablet: 10 }, { mobile: true, tablet: true }),
          margin: {
            desktop: { top: 45, bottom: 0, left: 0, right: 0 },
            tablet: { top: 45, bottom: 0, left: 0, right: 0 },
            mobile: { top: 32, bottom: 0, left: 0, right: 0 }
          }
        },
        attr: { class: 'w-[260px] md:w-full !mx-auto' }
      }),
      createElement<'translation'>({
        type: 'translation',
        name: 'Text 8',
        value: 'generate.templates.paymentSuccess.footerInfo',
        style: { textAlign: 'center', fontSize: '10px', color: '#737380', lineHeight: '1.5' },
        options: {
          visible: true,
          customizeFontColor: true,
          params: { link: 'https://dashboard.zotlo.com/user/login', style: 'color: #007AFF;', target: '_blank' },
          ...createResponsiveFontSizeOption({ mobile: 10, tablet: 10 }, { mobile: true, tablet: true })
        },
        attr: { class: 'w-[260px] md:w-full !mx-auto' }
      })
    ]
  })
  .done()
  .done();
